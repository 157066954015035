import orm, { Model } from "../common/orm";


export class News extends Model {
    static get fields() {
        return {};
    }
    static get source() {
        return "/api/db/news";
    }
    static get modelName() {
        return "News";
    }
}
orm.register(News);

export class Alerts extends Model {
    static get fields() {
        return {};
    }
    static get source() {
        return "/api/db/alerts";
    }
    static get modelName() {
        return "Alerts";
    }
}
orm.register(Alerts);

import React, { Component } from "react";
import { connect } from "react-redux";
import Grid from "../common/GridWrapper";
import Typography from "../common/TypographyWrapper";
import { Link } from "react-router-dom";
import {
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    ResponsiveContainer,
    BarChart,
    Bar,
    Cell,
    LabelList,
    Legend
} from "recharts";
import { Card, CardContent, Button } from "@mui/material";
import { CropYear } from "../field/fieldactivities/models";
import { Field as FieldModel, Farm, FarmCropYear } from "../field/models";
import * as navActions from "../common/actions";
import CardChip from "../common/CardChip";
import WordCloudChip from "../common/WordCloudChip";
import { createSelector } from "../common/orm";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";

const BarLbl = ({ x, y, width, value }) => {
    const formattedNumber = value.toLocaleString(undefined, { maximumFractionDigits: 0 });

    return (
        <text x={x + width / 2} y={y} dy={-5} fill={"8884d8"} fontSize={10} textAnchor="middle" fontWeight={"bold"}>
            {formattedNumber}
        </text>
    );
};

const allFarms = Farm.selectAll();

const allFields = FieldModel.selectAll();

const allFarmCropYears = FarmCropYear.selectAll();

const tickFormatter = number => number.toLocaleString(undefined, { maximumFractionDigits: 0 });

const cropYearDetails = createSelector(
    // Custom Selector for orderBy
    schema => {
        if (schema.Field.count() > 0 && schema.Farm.count() > 0) {
            const schemaProject = schema.Project.count();
            return schema.CropYear.all()
                .orderBy("year", "desc")
                .toModelArray()
                .map(cropYear => ({
                    // FIXME: These "if name else id" statements can probably be removed since the name is now forced to be defined
                    farm_name: cropYear.field.farm.name ? cropYear.field.farm.name : cropYear.field.farm.id,
                    farm_id: cropYear.field.farm.id,
                    field_name: cropYear.field.name ? cropYear.field.name : cropYear.field.id,
                    field_id: cropYear.field.id,
                    field_acres: cropYear.field.size && cropYear.field.size.split(" ")[0],
                    crop_name: cropYear.crop.name,
                    project_code:
                        schemaProject > 0 && cropYear.project_instance ? cropYear.project_instance.name : "N/A",
                    ...cropYear.ref,
                    fieldObject: cropYear.field._fields
                }));
        } else {
            return [];
        }
    }
);

const allEnrolledAcres = createSelector(
    (session) => {
        return session.Farm.all()
            .toModelArray()
            .map(farm => {
                return session.FarmCropYear.filter({ farm: farm.id })
                    .orderBy("year", "desc")
                    .toModelArray()
                    .map(farmCropYear => {
                        // Calculate the entered acres                        
                        var enteredtotal = 0;
                        farmCropYear.farm.fields.toModelArray().forEach(function (f) {
                            f.cropyears.toModelArray().forEach(function (cy) {
                                if (cy.year === farmCropYear.year && cy.crop.id === farmCropYear.crop.id) {
                                    enteredtotal = enteredtotal + parseFloat(f.size.split(" ")[0]);
                                }
                            });
                        });

                        var managedtotal = (parseFloat(farmCropYear.nonirrigated) + parseFloat(farmCropYear.irrigated));
                        var ratio = enteredtotal / managedtotal;
                        var enrolledtotal = ratio >= .1 && ratio <= 1 ? managedtotal : enteredtotal;

                        return {
                            crop_name: farmCropYear.crop.name,
                            managedtotal,
                            enteredtotal,
                            ratio,
                            enrolledtotal,
                            ...farmCropYear.ref
                        };
                    });

            })
    }
)

class Insights extends Component {

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    BuildLineData = () => {

        const { cropYears, fields } = this.props;

        const colors = ["#9f88be", "#ef8632", "#d0352b", "#549e3f", "#a6cee3", "#1f78b4", "#b2df8a", "#33a02c", "#fb9a99", "#e31a1c", "#fdbf6f", "#ff7f00", "#cab2d6", "#6a3d9a", "#ffff99", "#b15928"];

        let cropYearArr = [];

        cropYears.map(cy => {
            if (cy.year !== undefined) {
                let yr = cy.year;
                const cf = fields.find(f => f.id === cy.field);

                if (cf !== undefined && cf.size !== undefined) {
                    let acres = parseFloat(cf.size.split(" ")[0]);
                    cropYearArr.push({ year: yr, acres: acres });
                }
            }
        });

        const yearlyTotals = cropYearArr.reduce((acc, size, index) => {
            const existingYr = acc.find(item => item.name === size.year);

            if (existingYr) {
                existingYr.acres += size.acres;
            } else {
                acc.push({
                    name: size.year,
                    acres: size.acres,
                    color: colors[index % colors.length]
                });
            }
            return acc;
        }, []);

        return yearlyTotals;

    };

    buildCropColors = (uniqueAttributes) => {
        const cropColors = {};
        const colors = ["#a6cee3", "#1f78b4", "#b2df8a", "#33a02c", "#fb9a99", "#e31a1c", "#fdbf6f", "#ff7f00", "#cab2d6", "#6a3d9a", "#ffff99", "#ffed6f", "#9e0142", "#d53e4f", "#f46d43", "#fdae61", "#fee08b", "#ffffbf", "#e6f598", "#abdda4", "#66c2a5", "#3288bd", "#5e4fa2", "#b15928", "#8dd3c7", "#ffffb3", "#bebada", "#fb8072", "#80b1d3", "#fdb462", "#b3de69", "#fccde5", "#d9d9d9", "#bc80bd", "#ccebc5"];

        uniqueAttributes.forEach((crop, idx) => {
            if (!cropColors[crop]) {
                cropColors[crop] = colors[idx];
            }
        });
        return cropColors;
    };

    buildCropData = () => {

        const { cropYears } = this.props;

        const cropTotalsByYear = {};

        cropYears.forEach(cy => {
            if (!cropTotalsByYear[cy.year]) {
                cropTotalsByYear[cy.year] = { year: cy.year };
            }
            if (!cropTotalsByYear[cy.year][cy.crop_name]) {
                cropTotalsByYear[cy.year][cy.crop_name] = 0;
            }

            cropTotalsByYear[cy.year][cy.crop_name] += parseFloat(cy.field_acres);
        })

        return Object.values(cropTotalsByYear);
    };

    buildEnteredEnrolledData = () => {

        const { enrolledAcres } = this.props;

        const colors = ["#9e0142", "#d53e4f", "#f46d43", "#fdae61", "#fee08b", "#ffffbf", "#e6f598", "#abdda4", "#66c2a5", "#3288bd", "#5e4fa2", "#a6cee3", "#1f78b4", "#b2df8a", "#33a02c", "#fb9a99", "#e31a1c", "#fdbf6f", "#ff7f00", "#cab2d6", "#6a3d9a", "#ffff99", "#ffed6f", "#9e0142", "#d53e4f", "#f46d43", "#fdae61", "#fee08b", "#ffffbf", "#e6f598", "#abdda4", "#66c2a5", "#3288bd", "#5e4fa2", "#b15928", "#8dd3c7", "#ffffb3", "#bebada", "#fb8072", "#80b1d3", "#fdb462", "#b3de69", "#fccde5", "#d9d9d9", "#bc80bd", "#ccebc5", "#df8a45", "#be4235", "#679b4b", "#a6cee3", "#1f78b4", "#b2df8a", "#33a02c", "#fb9a99", "#e31a1c", "#fdbf6f", "#ff7f00", "#cab2d6", "#6a3d9a", "#ffff99"];

        const flattenedData = enrolledAcres.flat().sort((a, b) => a.year - b.year);

        let colorIdx = 0;

        const totalsByYear = flattenedData.reduce((acc, { year, enteredtotal, enrolledtotal }) => {
            if (!acc[year]) {
                acc[year] = { Entered: 0, Enrolled: 0, year: year, color: colors[colorIdx] };
                colorIdx += 1;
            }
            acc[year].Entered += enteredtotal;
            acc[year].Enrolled += enrolledtotal;
            return acc;
        }, {});

        return Object.values(totalsByYear);
    };

    render() {

        const { fields, cropYears } = this.props;

        const fieldAcres = fields.reduce((acc, item) => { return acc + parseFloat(item.size); }, 0);

        const uniqueCrops = Array.from(new Set(cropYears.flatMap(item => item.crop_name)));

        const uniqueProjs = Array.from(new Set(cropYears.flatMap(item => { return item.project_code !== "N/A" ? item.project_code : null; }))).filter(Boolean);

        const cropData = this.buildCropData();

        const uniqueAttributes = Array.from(new Set(cropData.flatMap(item => Object.keys(item).filter(key => key !== 'year'))));

        const cropColors = this.buildCropColors(uniqueAttributes);

        const enteredEnrolled = this.buildEnteredEnrolledData();

        return (
            <>
                <Grid container spacing={16} style={{ marginTop: "5px" }}>
                    <Typography>
                        The new Operations Insight page offers summary information about your data in the Platform. We’ll be adding additional insights elements over time. If you have suggestions for data you would like to see, please email staff at <a href="mailto:support@fieldtomarket.org">support@fieldtomarket.org</a>.
                    </Typography>
                    <Grid container spacing={16} style={{ marginTop: "5px" }}>
                        <Grid item xs={12}>
                            <Typography style={{ fontWeight: "bold", fontSize: "25px", marginTop: "10px" }}>
                                Summary of Data in Your Account
                            </Typography>
                            {fields.length > 0 ? (
                                <Typography>
                                    Below are summary data across years for all fields and acres entered in the Platform. This data includes both complete and incomplete crop years.
                                </Typography>
                            ) : (
                                <>
                                    <Typography>
                                        Please enter your first farm, field and crop year.
                                    </Typography>
                                    <Button
                                        component={Link}
                                        to={"/farm/add"}
                                        fullWidth
                                        variant="raised">
                                        <AddCircleOutlineIcon />
                                        &nbsp;&nbsp;&nbsp;Add New Farm
                                    </Button>
                                </>
                            )}
                        </Grid>
                        <Grid item xs={12} md={6} style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center', padding: '10px', marginTop: '10px' }}>
                            <CardChip number={fields !== undefined ? fields.length : 0} label={"FIELDS"} />
                            <CardChip number={Math.round(fieldAcres)} label={"UNIQUE FIELD ACRES"} />
                            <CardChip number={cropYears !== undefined ? cropYears.length : 0} label={"CROP YEARS"} />
                        </Grid>
                        <Grid item xs={12} md={6} style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center', padding: '10px', marginTop: '10px' }}>
                            <WordCloudChip words={uniqueCrops !== undefined ? uniqueCrops : ["No Crop Data"]} label="UNIQUE CROPS" />
                            <WordCloudChip words={uniqueProjs !== undefined ? uniqueProjs : ["Not currently opted into any projects"]} label="PROJECTS OPTED INTO" />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography style={{ fontWeight: "bold", fontSize: "25px", marginTop: "10px" }}>
                                Total Entered Acres by Crop
                            </Typography>
                            <Card>
                                <CardContent>
                                    <Grid style={{ marginTop: "10px", height: "350px" }}>
                                        <ResponsiveContainer width="95%" height="90%">
                                            <BarChart
                                                width={500}
                                                height={300}
                                                data={cropData}>
                                                <CartesianGrid strokeDasharray="3 3" />
                                                <XAxis dataKey="year" />
                                                <YAxis />
                                                <Tooltip />
                                                <Legend />
                                                {uniqueAttributes.map((attr, index) => (
                                                    <Bar barSize={40} key={index} dataKey={attr} stackId="a" fill={cropColors[attr]} />
                                                ))}
                                            </BarChart>
                                        </ResponsiveContainer>
                                    </Grid>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography style={{ fontWeight: "bold", fontSize: "25px", marginTop: "10px" }}>
                                Total entered and enrolled acres across years
                            </Typography>
                            <Card>
                                <CardContent>
                                    <Grid style={{ height: "350px" }}>
                                        <ResponsiveContainer width="95%" height="90%">
                                            <BarChart
                                                width={500}
                                                height={300}
                                                data={enteredEnrolled}
                                                margin={{
                                                    top: 40,
                                                    right: 30,
                                                    left: 0,
                                                    bottom: 5
                                                }}>
                                                <defs>
                                                    {enteredEnrolled.map((entry, index) => (
                                                        <pattern
                                                            key={`hatch${index}`}
                                                            id={`hatch${index}`}
                                                            width="5"
                                                            height="5"
                                                            patternUnits="userSpaceOnUse"
                                                            patternTransform="rotate(45 0 0)">
                                                            <line
                                                                x1="0"
                                                                y1="0"
                                                                x2="0"
                                                                y2="5"
                                                                style={{ stroke: entry.color, strokeWidth: "5" }}
                                                            />
                                                        </pattern>
                                                    ))}
                                                </defs>
                                                <CartesianGrid strokeDasharray="3 3" />
                                                <XAxis dataKey="year" padding={{ left: 30, right: 30 }} />
                                                <YAxis tickFormatter={tickFormatter} />
                                                <Tooltip />
                                                <Bar dataKey="Entered">
                                                    {enteredEnrolled.map((entry, index) => (
                                                        <Cell key={`cell-${index}`} fill={entry.color} />
                                                    ))}
                                                    <LabelList content={<BarLbl />} position="top" />
                                                </Bar>
                                                <Bar dataKey="Enrolled">
                                                    {enteredEnrolled.map((entry, index) => (
                                                        <Cell key={`cell-${index}`} fill={`url(#hatch${index})`} />
                                                    ))}
                                                    <LabelList content={<BarLbl />} position="top" />
                                                </Bar>
                                            </BarChart>
                                        </ResponsiveContainer>
                                    </Grid>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </Grid>
            </>
        );
    }
}

Insights = connect((state, ownProps) => ({
    fields: allFields(state, ownProps),
    farms: allFarms(state, ownProps),
    cropYears: cropYearDetails(state),
    farmCropYears: allFarmCropYears(state, ownProps),
    enrolledAcres: allEnrolledAcres(state, ownProps),
    navState: state.nav,
    authState: state.auth,
    syncState: state.sync
}),
    {
        ...CropYear.actions,
        ...FarmCropYear.actions,
        navActions
    })(Insights);

export default (Insights);
